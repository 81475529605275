<template>
  <div class="container position-lift limiter">
    <div class="col__one">
      <div class="section__title">
        <div class="section__title">
          <h1>
            Make it <br />
            interactive
          </h1>
          <span>
            New to product animations or know your <br />
            way around - no worries.</span
          >
          <button class="full">
            Coming Soon
            <icon name="arrow" class="arrow" />
          </button>
        </div>
      </div>
      <div class="card__one card__interactive">
        <div class="card card--dark card--shadow-dark box box__ditch">
          <h2>State based animations</h2>
          <span
            >We keep it simple, so you’re free to create powerful results - no After
            Effects qualifications needed.</span
          >
        </div>
      </div>
      <div class="card__two card__interactive">
        <div class="card card--dark card--shadow-dark box box__ditch">
          <h2>State based animations</h2>
          <span
            >We keep it simple, so you’re free to create powerful results - no After
            Effects qualifications needed.</span
          >
        </div>
      </div>
    </div>
    <div class="col__two">
      <div class="usecase__one">
        <div class="usecase__first card card--dark usecase--ease">
          <div class="card__header">
            <div class="card__info">
              <span class="card__title">E-Commerce Animation</span>
              <span class="card__sub">Edited 6 hours ago</span>
            </div>
          </div>
          <div class="sample_container">
            <lottie-interactive
              path="https://assets4.lottiefiles.com/packages/lf20_wesmb2eu.json"
              interaction="click"
              style="cursor: pointer; width: 100%"
            ></lottie-interactive>
          </div>
        </div>
      </div>
      <div class="usecase__two">
        <div class="usecase__first card card--dark usecase--ease">
          <div class="card__header">
            <div class="card__info">
              <span class="card__title">E-Commerce Animation</span>
              <span class="card__sub">Edited 6 hours ago</span>
            </div>
          </div>
          <div class="sample_container">
            <lottie-interactive
              path="https://assets4.lottiefiles.com/packages/lf20_wesmb2eu.json"
              interaction="switch"
              style="cursor: pointer; width: 100%"
            ></lottie-interactive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon from "@/assets/Icons.vue";
import { LottieInteractive } from "lottie-interactive";
export default {
  name: "SimpleForEveryone",
  data() {
    return {
      controller: null,
      tween: null,
      scene: null,
    };
  },
  components: {
    icon,
    LottieInteractive,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 32px 32px;
  grid-auto-flow: row;
  justify-items: stretch;
  grid-template-areas:
    "col__one"
    "col__two";
  padding-inline: var(--step-4);
  padding-block: var(--step-0);
  height: max-content;
  color: var(--primary);
  margin-inline: auto;

  .card {
    justify-content: center;
    &__header {
      color: var(--primary) !important;
    }

    &--dark {
      border: none !important;
    }
  }

  .col__one {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    gap: 32px 32px;
    grid-auto-flow: row;
    grid-template-areas:
      "section__title section__title"
      "card__one card__one"
      "card__two card__two";
    grid-area: col__one;
    height: 100%;
    align-items: end;

    .card {
      height: max-content !important;
      transition: all 75ms ease;
    }

    .section__title {
      grid-area: section__title;
      span {
        line-height: var(--step-0);
      }
      button {
        gap: var(--step--4);
        align-items: center;
        height: max-content;
        color: var(--teal);

        .arrow {
          display: flex;
          stroke: var(--teal);
        }
      }
    }

    .card__one {
      grid-area: card__one;
    }

    .card__two {
      grid-area: card__two;
    }

    .card__interactive {
      h2 {
        font-size: var(--step--1);
      }
      span {
        font-size: var(--step--4);
        line-height: var(--step-0);
      }
    }
  }
}

.col__two {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  gap: 32px;
  grid-auto-flow: row;
  grid-template-areas:
    "usecase__one"
    "usecase__two";
  grid-area: col__two;

  .card {
    height: max-content !important;
    transition: all 75ms ease;

    .sample_container {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        background-color: rgba(43 43 43 / 0.8);
        color: var(--teal);
        padding-inline: var(--step-1);
        padding-block: var(--step--3);
        border-radius: var(--step-0);
        font-size: var(--step--3);
        font-weight: var(--semi-bold);
        line-height: var(--step-0);
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);
      }
    }
    &:focus-visible,
    &:hover {
      .card__header {
        color: var(--teal) !important;
      }

      .sample__action {
        button {
          transform: translateY(0);
        }
      }
    }
  }
}
.usecase__one {
  align-items: flex-start;
}

.usecase__two {
  grid-area: usecase__two;
}

@media (min-width: 980px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 32px 32px;
    grid-auto-flow: row;
    justify-items: stretch;
    grid-template-areas: "col__one col__two";
    color: var(--primary);

    .card {
      justify-content: center;
      &__header {
        color: var(--primary) !important;
      }

      &--dark {
        border: none !important;
      }
    }

    .col__one {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 32px 32px;
      grid-auto-flow: row;
      grid-template-areas:
        "section__title section__title"
        "card__one card__two";
      grid-area: col__one;

      .section__title {
        grid-area: section__title;
        span {
          line-height: var(--step-0);
        }
        button {
          color: var(--teal);
        }
      }

      .card__one {
        grid-area: card__one;
      }

      .card__two {
        grid-area: card__two;
      }
    }
  }

  .col__two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 32px;
    grid-auto-flow: row;
    grid-template-areas: "usecase__one usecase__two";
    grid-area: col__two;

    .card {
      height: max-content !important;
      transition: all 75ms ease;

      .sample_container {
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: fit-content;
          background-color: rgba(43 43 43 / 0.8);
          color: var(--teal);
          padding-inline: var(--step-1);
          padding-block: var(--step--3);
          border-radius: var(--step-0);
          font-size: var(--step--3);
          font-weight: var(--semi-bold);
          line-height: var(--step-0);
          -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
        }
      }
      &:focus-visible,
      &:hover {
        .card__header {
          color: var(--teal) !important;
        }

        .sample__action {
          button {
            transform: translateY(0);
          }
        }
      }
    }
  }
  .usecase__one {
    display: flex;
    align-items: flex-start;
    grid-area: usecase__one;
  }

  .usecase__two {
    display: flex;
    align-items: flex-end;
    grid-area: usecase__two;
  }
}

@media (min-width: 1080px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 32px 32px;
    grid-auto-flow: row;
    justify-items: stretch;
    grid-template-areas: "col__one col__two";
    color: var(--primary);

    .card {
      justify-content: center;
      &__header {
        color: var(--primary) !important;
      }

      &--dark {
        border: none !important;
      }
    }

    .col__one {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 32px 32px;
      grid-auto-flow: row;
      grid-template-areas:
        "section__title section__title"
        "card__one card__two";
      grid-area: col__one;

      .section__title {
        grid-area: section__title;
        span {
          line-height: var(--step-0);
        }
        button {
          color: var(--teal);
        }
      }

      .card__one {
        grid-area: card__one;
      }

      .card__two {
        grid-area: card__two;
      }
    }
  }
}
</style>
