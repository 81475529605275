import { render, staticRenderFns } from "./SimpleForEveryone.vue?vue&type=template&id=9460efee&scoped=true&"
import script from "./SimpleForEveryone.vue?vue&type=script&lang=js&"
export * from "./SimpleForEveryone.vue?vue&type=script&lang=js&"
import style0 from "./SimpleForEveryone.vue?vue&type=style&index=0&id=9460efee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9460efee",
  null
  
)

export default component.exports